// import { icons } from './icons';

// export const productList = [
//   { img: icons.Pot1, title: 'Item 1', price: 200 },
//   { img: icons.Pot2, title: 'Item 2', price: 150 },
//   { img: icons.Pot3, title: 'Item 3', price: 156 },
//   { img: icons.Pot4, title: 'Item 4', price: 500 },
//   { img: icons.Sculpture1, title: 'Item 5', price: 1599 },
//   { img: icons.Sculpture2, title: 'Item 6', price: 999 },
//   { img: icons.Sculpture3, title: 'Item 7', price: 599 },
//   { img: icons.Pot1, title: 'Item 1', price: 200 },
//   { img: icons.Pot2, title: 'Item 2', price: 150 },
//   { img: icons.Pot3, title: 'Item 3', price: 156 },
//   { img: icons.Pot4, title: 'Item 4', price: 500 },
//   { img: icons.Sculpture1, title: 'Item 5', price: 1599 },
//   { img: icons.Sculpture2, title: 'Item 6', price: 999 },
//   { img: icons.Sculpture3, title: 'Item 7', price: 599 },
//   { img: icons.Pot1, title: 'Item 1', price: 200 },
//   { img: icons.Pot2, title: 'Item 2', price: 150 },
//   { img: icons.Pot3, title: 'Item 3', price: 156 },
//   { img: icons.Pot4, title: 'Item 4', price: 500 },
//   { img: icons.Sculpture1, title: 'Item 5', price: 1599 },
//   { img: icons.Sculpture2, title: 'Item 6', price: 999 },
//   { img: icons.Sculpture3, title: 'Item 7', price: 599 },
// ];

export const categoryList = [
  { title: 'Sculpture', quantity: 250 },
  { title: 'Pots', quantity: 150 },
  { title: 'Art', quantity: 100 },
  { title: 'Furniture', quantity: 550 },
  { title: 'Home accessories', quantity: 459 },
  { title: 'Laptops', quantity: 500 },
  { title: 'Mobile', quantity: 1200 },
  { title: 'Watch', quantity: 4500 },
  { title: 'Sculpture', quantity: 250 },
  { title: 'Pots', quantity: 150 },
  { title: 'Art', quantity: 100 },
  { title: 'Furniture', quantity: 550 },
  { title: 'Home accessories', quantity: 459 },
  { title: 'Laptops', quantity: 500 },
  { title: 'Mobile', quantity: 1200 },
  { title: 'Watch', quantity: 4500 },
];

export const companyList = [
  { title: 'Company 1' },
  { title: 'Company 2' },
  { title: 'Company 3' },
  { title: 'Company 4' },
  { title: 'Company 5' },
  { title: 'Company 6' },
  { title: 'Company 7' },
  { title: 'Company 8' },
  { title: 'Company 9' },
  { title: 'Company 10' },
  { title: 'Company 11' },
  { title: 'Company 12' },
  { title: 'Company 13' },
  { title: 'Company 14' },
  { title: 'Company 15' },
  { title: 'Company 16' },
  { title: 'Company 17' },
];
