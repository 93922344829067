import { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { loginSchema } from '../../utils/validationSchemas';
import { Strings } from '../../utils/strings';
import { useNavigate } from 'react-router-dom';
import { login } from '../../firebase';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = (props) => {
  const navigate = useNavigate();
  const [isPwdShow, setIsPwdShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      login(
        values,
        () => {
          setLoading(false);
          navigate('/');
          toast.success('Login successfully.');
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      );
    },
  });

  const { values, errors, touched, handleChange, handleSubmit } = formik;

  return (
    <Stack flex={1} alignItems='center' justifyContent='center' height='100%'>
      <Stack maxWidth={550} width='100%' py={5} px={3}>
        <Stack alignItems='center' py={2.5}>
          <Typography variant='h2'>{Strings.LOGIN}</Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                variant='outlined'
                type='email'
                name='email'
                label={Strings.EMAIL}
                placeholder={Strings.ENTER_EMAIL}
                value={values.email}
                onChange={handleChange('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                variant='outlined'
                type={isPwdShow ? 'text' : 'password'}
                name='password'
                label={Strings.PASSWORD}
                placeholder={Strings.ENTER_PASSWORD}
                value={values.password}
                onChange={handleChange('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() => setIsPwdShow(!isPwdShow)}
                    >
                      <IconButton style={{ fontSize: 'inherit' }}>
                        <FontAwesomeIcon
                          icon={!isPwdShow ? faEye : faEyeSlash}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                loading={loading}
                disabled={loading}
                fullWidth
                size='large'
                sx={{ mt: 3 }}
                type='submit'
                variant='contained'
              >
                {Strings.LOGIN}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </Stack>
  );
};

export default Login;
