import { Container, Grid, Stack } from '@mui/material';
import React from 'react';
import { companyList } from '../../utils/dummyData';
import CategoryCard from '../../components/CategoryCard';
import { useNavigate } from 'react-router-dom';

const Companies = () => {
  const navigate = useNavigate();

  return (
    <Stack py={3}>
      <Container>
        <Grid container spacing={2}>
          {companyList?.map((el, index) => {
            return (
              <Grid item key={index} xs={6} sm={4} md={3} lg={2.4}>
                <CategoryCard
                  data={{ ...el }}
                  onClick={() => navigate('/products')}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Stack>
  );
};

export default Companies;
